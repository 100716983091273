<template>
  <div class="">
    <Card class="p-m-2">
      <template #title>
        Mail Gönder
      </template>
      <template #content>

        <div class="p-grid">
          <div class="p-col-7 p-md-7 p-lg-7 ">
            <div class="p-grid p-fluid p-formgrid">
              <div class="p-field p-col-6">
                <span for="Baslik">Başlık</span>
                <InputText type="text" v-model="Baslik" aria-labelledby="Baslik" />
              </div>
              <div class="p-field p-col-6">
                <span for="Konu">Konu</span>
                <InputText type="text" v-model="Konu" aria-labelledby="Konu" />
              </div>
              <div class="p-field p-col-12">
                <span for="Icerik">İçerik</span>
                <Textarea v-model="Icerik" rows="5" cols="30" />
              </div>
              <div class="p-field p-col-12 p-d-flex p-ai-end">
                <Button :disabled="button_disable" label="Gönder" icon="pi pi-send" class="p-button-sm p-button-danger" @click="this.Gonder()" />
              </div>
            </div>

          </div>

          <div class="p-col-5 p-md-5 p-lg-5 ">
            <DataTable_Custom v-if="DataTable_render" :columns="columns" :data="MukellefListesi"
              v-on:selected_rows="getSelectedRows" :selectionmode="true" :detailbutton="false" datakey="rowid" />
          </div>
        </div>

      </template>
    </Card>
  </div>
</template>

<script>
import GlobalServis from "../../../services/GlobalServis";
import DataTable_Custom from "../DataTable/DataTable.vue";
export default {
  data() {
    return {
      DataTable_render: true,
      button_disable: false,
      MukellefListesi: [],
      selectedrows: [],
      columns: [
        { field: "Unvan", header: "Ünvan" },
        { field: "Mail", header: "Mail" },
      ],
      
      Mesaj_tur : '',
      Icerik : '',
      Konu : '',
      Baslik : '',
    };
  },
  methods: {
    Gonder() {
      this.button_disable = true;
      if (this.selectedrows.length === 0) {
        this.$toast.add({
          severity: "warn",
          summary: "Uyarı",
          detail: "Alıcı seçin.",
          life: 3000,
        });
        
        this.button_disable = false;
      }
      else {
        this.selectedrows.forEach(element => {
          setInterval(() => { }, 500);

          let data = {
            Mesaj_tur: 'MAIL',
            Kime: element.Mail,
            Icerik : this.Icerik,
            Konu : this.Konu,
            Baslik : this.Baslik
          }

          GlobalServis.GlobalServis("POST", "MesajGonder", data).then(
            (res) => {
              if (res.status == 200) {
                if (!res.data.Sonuc) {
                if (res.data.Tur == "MAIL") {
                  this.$toast.add({
                    severity: "warn",
                    summary: "Uyarı",
                    detail: "Mail hakkınız bitmiştir.",
                    life: 3000,
                  });
                }
                else if (res.data.Tur == "SMS") {
                  this.$toast.add({
                    severity: "warn",
                    summary: "Uyarı",
                    detail: "Sms hakkınız bitmiştir.",
                    life: 3000,
                  });
                }
              }
              } else if (res.status == 400) {
                this.$toast.add({
                  severity: "error",
                  summary: "Hata",
                  detail: "Önemli bir hata meydana geldi",
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Hata",
                  detail: "Bilinmeyen bir hata meydana geldi",
                  life: 3000,
                });
              }
            }
          );

        })

          this.$toast.add({
          severity: "success",
          summary: "Başarılı",
          detail: "Gönderildi.",
          life: 3000,
        });

        setInterval(() => {
          this.button_disable = false;
         }, 5000);

      }
    },
    getSelectedRows(SelectedRows) {
      this.selectedrows = SelectedRows;
    },
    InitServis() {
      this._GetBildirimTablosu();
    },
    _GetBildirimTablosu() {
      let data = {
        Tip: 'MAIL'
      }
      GlobalServis.GlobalServis("POST", "GetMukellefIletiInfo", data).then(
        (res) => {
          if (res.status == 200) {
            this.MukellefListesi = res.data;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        }
      )
    },
  },
  mounted() {
    this.InitServis();
  },
  components: {
    DataTable_Custom
  },
};
</script>

<style scoped></style>